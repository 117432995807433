import { AxiosError } from "axios";
import store from "@/store";
import router from "@/router";
import {
  hasMachineViewPermission,
  hasUserViewPermission,
} from "@/utils/UserPermissions";

export const errorsHandler = (error: AxiosError): void => {
  const errorMessage = error?.response?.data?.message || "Errore sconosciuto";
  const errorCode = error.response?.status;
  if (errorCode === 401) {
    store.commit("mutation_open_snackbar_error", errorMessage);
    store.dispatch("logout").then(() => {
      router.replace("/login");
    });
  } else if (errorCode === 403) {
    store.commit("mutation_open_snackbar_error", errorMessage);
    if (hasMachineViewPermission(store.getters.userRoles)) {
      router.replace("/machines").catch(() => {
        store.dispatch("logout").then(() => {
          router.replace("/login");
        });
      });
    } else if (hasUserViewPermission(store.getters.userRoles)) {
      router.replace("7users").catch(() => {
        store.dispatch("logout").then(() => {
          router.replace("/login");
        });
      });
    } else {
      router.replace("/blank").catch(() => {
        // Non ci sono altre pagine di permesso, quindi procedo al logout
        store.dispatch("logout").then(() => {
          router.replace("/login");
        });
      });
    }
  }
};
