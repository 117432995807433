














import Vue from "vue";

export default Vue.extend({
  name: "ActionButton",

  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
